<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Download Exports</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"> <router-link to="/employees-payroll"> Employees Payroll </router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> <router-link to="/payroll-export"> Generate Exports </router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> Download Exports </li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>

            <b-row>
                <b-col lg="2" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap">Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select v-model="urlBody.year" :options="years" disabled-field="notEnabled"></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col lg="2" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap">Month</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select v-model="urlBody.month" :options="months" disabled-field="notEnabled"></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col lg="1" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-button variant="primary" @click="onFiltered">Filter</b-button>
                        </div>
                    </div>
                </b-col>
                <b-col lg="7" class="mb-1rem text-right" >
                    <router-link :to="{name:'error-logs'}">  
                        <b-button variant="light" v-b-tooltip.hover title="Logs"> 
                            <b-icon-card-list />
                        </b-button>
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                @sort-changed="customSorting"
                :items="parent_rows"
                :fields="parent_columns"
                no-local-sorting
                @row-clicked="toggleDetails"
                responsive
                tbody-tr-class="pointer-cursor"
            >
                <!-- CHILD TABLE -->
                <template v-slot:row-details="row">
                    <b-card>
                        <span style="float:right; cursor: pointer" class="my-2">
                            <font-awesome-icon icon="times" @click="row.toggleDetails"></font-awesome-icon>
                        </span>

                        <b-table
                            show-empty
                            striped
                            hover
                            :items="row.item.payroll_export_data"
                            :fields="child_columns"
                            responsive
                            v-if="row.item.payroll_export_data.length>0"
                            tbody-tr-class="pointer-default"
                        >
                            <template #cell(SNO)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template v-slot:cell(month)="row">
                                {{getMonthName(row.item.month)}}
                            </template>
                            <template v-slot:cell(actions)="row" v-if="hasPermission('CAN_DOWNLOAD_PAYROLL')">
                                <div class="actions">
                                    <a href="#" @click="downloadFile(row.item.id, row.item.file_name)" v-b-tooltip.hover title="Download" class="circle"> 
                                        <font-awesome-icon icon="download" class="text-right "></font-awesome-icon>
                                    </a>
                                </div>
                            </template>
                        </b-table>

                        <b-alert
                            show
                            variant="danger"
                            style="width: 95%;"
                            v-else
                        >{{ row.item.error_message ? row.item.error_message : 'Unable to generate exports . Please contact Administrator.' }}</b-alert>
                    </b-card>
                </template>

                <template v-slot:cell(employees)="row">
                    {{ row.item.employee_ids == 'all' ? 'All Employees' : JSON.parse(row.item.employee_ids).length+' Employee'+(JSON.parse(row.item.employee_ids).length>1 ? 's': '') }}
                </template>
                <template v-slot:cell(month)="row">
                    {{ getMonthName(row.item.month) }}
                </template>
                <template v-slot:cell(status)="row">
                    <b-badge :variant="setBadgeColor(row.item.status)"> {{ row.item.status }} </b-badge>
                </template>
            </b-table>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                year: '',
                month: '',
                sort_by: "",
                order: "",
            },
            api_error: "",
            months: [
                { value: 1, text: 'January' },
                { value: 2, text: 'Febuary' },
                { value: 3, text: 'March' },
                { value: 4, text: 'April' },
                { value: 5, text: 'May' },
                { value: 6, text: 'June' },
                { value: 7, text: 'July' },
                { value: 8, text: 'August' },
                { value: 9, text: 'September' },
                { value: 10, text: 'October' },
                { value: 11, text: 'November' },
                { value: 12, text: 'December' }
            ],
            parent_rows: [],
            parent_columns: [
                { key: 'type', label: 'Export Type', sortable: true },
                { key: 'employees' },
                { key: 'month' },
                { key: 'year' },
                { key: 'generated_by_employee.full_name', label: 'Generated By' },
                { key: 'created_at', label: 'Generated At' , sortable: true},
                { key: 'status' },
            ],
            child_columns: [
                { key: "SNO", label:"S.No" },
                { key: "file_name" },
                { key: "month" },
                { key: "year" },
                { label:"Generated By", key: "generate.full_name" },
                { key: "generated_at" },
                { key: "actions", label:"Actions" }
            ],
            child_rows: [],
            badge_colors:[
                { status: "GENERATING", color:'warning' },
                { status: "FAILED", color:'danger' },
                { status: "COMPLETED", color:'success' },
            ],
            filter: null,
            access: true,
            years: [],
            total_years:3,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            totalRows:"",
            pageOptions: [10, 25, 50, 100],
            formats: [],
            host: this.$api.host_url,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_PAYROLL");
        this.getPreviousMonth();
        this.getYears();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.getGeneratedPayrolls(urlBody);
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        getYears() {
            const currentYear = (new Date()).getFullYear()+1;
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
            this.onPageLoad();
        },

        downloadFile(id, filename) {
            var query = this.getAuthHeaders();
            query["params"] = {
                id: id
            };
            query["responseType"] = 'blob';
            this.axios.get(this.$api.download_file, query).then(response => {
                var url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                var query = this.getAuthHeaders();
                query["responseType"] = 'JSON';
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                }
                this.showAlert();
            });
        },

        getPreviousMonth(){
            var  months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
            var d = new Date();
            let get_month = months[d.getMonth()];
            let year = '';
            let month = '';
            if (get_month == 1){
                year = (new Date()).getFullYear() -1;
                month = 12;
            } else {
                year = new Date().getFullYear()
                month =get_month-1;
            }

            this.urlBody.year = year;
            this.urlBody.month = month;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody);
            this.getGeneratedPayrolls(api_params);
        },

        onFiltered() {
            this.getGeneratedPayrolls(this.urlBody);
        },

        setDefaultFilters() {
            let api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.year = api_params.year
                ? api_params.year
                : this.urlBody.year;
            this.urlBody.month = api_params.month
                ? api_params.month
                : this.urlBody.month;
        },

        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            return data.color;
        },

        getGeneratedPayrolls: function (params) {
            this.startProgressBar();
            this.parent_rows = [];
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_generated_payrolls, query).then(response => {
                let payrolls = response.data.data;
                this.parent_rows = payrolls;
                this.parent_rows.forEach(row => {
                    row['payroll_export_data'] = [];
                });
                this.$router.replace({
                    path: this.$route.params[0],
                    query: params
                }).catch(()=>{})
                this.setDefaultFilters();
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        toggleDetails(row_data) {
            if (Object.prototype.hasOwnProperty.call(row_data, '_showDetails') && row_data._showDetails) {
                this.$set(row_data, '_showDetails', false);
                return;
            }
            
            this.getExcellExports(row_data);
        },

        getExcellExports(row_data) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                generate_payroll_id: row_data.id
            };
            this.axios.get(this.$api.payroll_exports, query).then(response => {
                const result = response.data.data;
                row_data.payroll_export_data = result;
                this.$set(row_data, '_showDetails', true);
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.pointer-cursor {
    cursor:pointer !important;
}
.pointer-default {
    cursor: default !important;
}

</style>
